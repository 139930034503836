
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import moment from "moment";
import { StyleCheck } from "@/mixins/style-check";
import FilePicker from "@/components/Input/FilePicker.vue";
import { Status } from "@/models/status.interface";
import { Milestone } from "@/models/milestone.interface";
import { Product } from "@/models/product.interface";
import CategoryBreadcrumbs from "@/components/Layout/Common/CategoryBreadcrums.vue";
import { Category } from "@/models/category.interface";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    FilePicker,
    StyleCheck,
    CategoryBreadcrumbs,
    DotsMenu,
  },
})
export default class Returns extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  search = "";
  dialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  selectedSubscriptors = [];
  options = {};
  dialogConfirm = false;
  dialogMilestone = false;
  milestoneBase: Milestone & { product?: string; category?: any } = {
    date: moment().format("YYYY-MM-DD"),
    title: "",
    description: "",
    product: undefined,
  };
  createMilestone = {
    date: moment().format("YYYY-MM-DD"),
    title: "",
    description: "",
    product: undefined,
    category: undefined,
  };
  $refs!: {
    milestoneForm: HTMLFormElement;
  };
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };
  selectedReturn?: Record<string, any>;
  groupMilestone = false;
  private returnBaseInfo = {
    client: "",
    date: "",
    amount: "",
    product: "",
  };
  returnInfo = this.returnBaseInfo;
  loadingProducts = false;
  productSearch = "";
  categories: { all: Category[]; tree: Category[] } = {
    all: [],
    tree: [],
  };
  setInfo() {
    if (this.selectedReturn) {
      this.returnInfo = {
        client: this.selectedReturn.client.email,
        date: this.formatedDate(this.selectedReturn.end_date),
        amount: this.$options.filters?.currency(this.selectedReturn.amount),
        product: this.selectedReturn.products_bill[0].product.name,
      };
    }
  }
  openMilestone(item?: Record<string, any>, group = false) {
    if (group) {
      this.returnInfo.client = this.$tc("Milestones.group");
    } else {
      this.selectedReturn = item;
      this.setInfo();
    }
    this.groupMilestone = group;
    this.dialogMilestone = true;
  }
  closeMilestone() {
    this.resetFormValidations([this.$refs.milestoneForm]);
    this.createMilestone = Object.assign(
      this.createMilestone,
      this.milestoneBase
    );
    this.selectedReturn = undefined;
    this.dialogMilestone = false;
    this.returnInfo = this.returnBaseInfo;
    this.groupMilestone = false;
  }
  openConfirm(item: Record<string, any>) {
    this.selectedReturn = item;
    this.setInfo();
    this.dialogConfirm = true;
  }
  closeConfirm() {
    this.selectedReturn = undefined;
    this.dialogConfirm = false;
    this.returnInfo = this.returnBaseInfo;
  }
  async releaseReturn() {
    if (this.selectedReturn) {
      try {
        this.loading = true;
        await this.$store.dispatch("bill/updateBillStatus", {
          id: this.selectedReturn.id,
          status: this.$constants.STATUS.PAID,
        });
        const Success: Notification = {
          message: this.$tc("Returns.confirmSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
        this.loadPendingReturns();
        this.closeConfirm();
      } catch (error) {
        const Error: Notification = {
          message: this.$tc("Returns.confirmError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      } finally {
        this.loading = false;
      }
    }
  }
  paymentColor(status?: Status | string): string {
    const statuses = this.$constants.STATUS;
    switch (typeof status == "string" ? status : status?.name) {
      case statuses.ON_TIME:
        return "indigo";
      case statuses.IN_ADVANCE:
        return "green";
      case statuses.DELAYED:
        return "amber darken-4";
      default:
        return "grey lighten-1";
    }
  }
  checkPaymentTime(bill: any): boolean {
    const treshold = moment()
      .add(process.env.VUE_APP_RETURN_TIMESPAN_DAYS || 2, "days")
      .format();
    if (
      bill.products_bill[0].product.investment.is_manual &&
      bill.status.name == this.$constants.STATUS.IN_PROCESS &&
      bill.end_date <= treshold
    ) {
      return true;
    }
    return false;
  }
  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getBills(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getBills(
    page: number,
    size: number,
    bill_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getBills", { page, size, bill_id, operation: "plus" })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.r-e11"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }
  private async loadCategories() {
    this.categories = await this.$store
      .dispatch("categories/getAssignableCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }
  private async created() {
    this.loader = true;
    this.loadCategories();
    await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
    this.loadPendingReturns();
    this.getProducts();
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.r-h1"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      // {
      //   text: this.$tc("Views.r-h3"),
      //   value: "date",
      //   align: "center",
      //   sortable: false,
      // },
      {
        text: this.$tc("Views.r-h4"),
        value: "progress",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.r-h6"),
        value: "payment_status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get bills(): any[] {
    let response = this.$store.getters["bill/getBills"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);
    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private billDetail(bill_id: string) {
    this.navigate("/staff/returns/detail/" + bill_id);
  }

  private paymentValidation(bill_id: string) {
    this.navigate("/staff/investments/payment-validation/" + bill_id);
  }

  private billHavePaymentsForValidation(bill: any): boolean {
    let response = false;

    bill.payments_cryptocurrency.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    bill.payments_traditional.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    return response;
  }
  async addMilestone() {
    if (this.$refs.milestoneForm.validate()) {
      this.loading = true;
      try {
        await this.$store.dispatch("milestone/createMilestone", {
          ...(this.groupMilestone
            ? { product: this.createMilestone.product }
            : { bill: this.selectedReturn?.id }),
          milestone: {
            date: this.createMilestone.date,
            description: this.createMilestone.description,
            title: this.createMilestone.title,
          },
        });
        const Success: Notification = {
          message: this.$tc("Milestones.createSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.closeMilestone();
      } catch (error) {
        const Error: Notification = {
          message: this.$tc("Milestones.createError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
      this.loading = false;
    }
  }
  private getInvestmentTimeline(bill: any) {
    const investment_start_date = moment(bill.date);
    const investment_end_date = moment(bill.end_date);
    const investment_current_date = moment();

    const investment_amount = bill.amount;

    const total_investment_time = investment_end_date.diff(
      investment_start_date
    );
    const current_investment_time = investment_current_date.diff(
      investment_start_date
    );
    let investment_current_percentage =
      bill.status?.name === this.$constants.STATUS.FINISHED
        ? 100
        : Math.trunc((100 * current_investment_time) / total_investment_time);

    if (investment_current_percentage < 0) {
      investment_current_percentage = 0;
    }

    if (investment_current_percentage > 100) {
      investment_current_percentage = 100;
    }

    return {
      investment_amount: investment_amount,
      investment_start_date: investment_start_date,
      investment_end_date: investment_end_date,
      investment_current_date: investment_current_date,
      total_investment_time: total_investment_time,
      current_investment_time: current_investment_time,
      investment_current_percentage: investment_current_percentage,
    };
  }

  //Search by ID
  private async searchReturnByID(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getBills(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
  private loadPendingReturns() {
    this.$store.dispatch("bill/getPendingBills", {
      operation: "plus",
      page: 1,
      size: 1,
    });
  }
  get locale() {
    return this.$i18n.locale;
  }
  private customCatFilter(item: Category, queryText) {
    const text: string = item.name as string;
    const searchText = queryText.toLowerCase();

    return text.toLowerCase().indexOf(searchText) > -1;
  }

  customFilter(item, queryText: string) {
    let text = item.name?.toLowerCase() + item.description.toLowerCase();

    const searchText = queryText.toLowerCase().replaceAll(/\s/g, "");

    return text.indexOf(searchText) > -1;
  }
  private getProducts(text?: string) {
    this.loadingProducts = true;

    this.$store
      .dispatch("products/getProducts", {
        page: 1,
        size: 8,
        status: this.$constants.STATUS.ACTIVE,
        category: this.createMilestone.category,
        is_group: true,
        with_returns: true,
        text,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Products.fetchError.products"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loadingProducts = false;
      });
  }
  @Watch("productSearch")
  private productSearchHandler(value: string) {
    if (value == null) return;

    if (this.loadingProducts) return;
    this.getProducts(value);
  }

  get products(): Product[] {
    const response = this.$store.getters["products/getProducts"];
    if (response) return response.products;
    return [];
  }
}
